<template>
  <div id="cart-commande" class="cart">
    <CartCommandeShop
      v-for="shop in shops"
      :key="shop.id"
      :shop="shop"
      :offers="shop.offers"
    />
    <div v-if="!cart.minimumReached()" class="minimum-panier-msg">
      <img src="@/assets/attention-red.svg">
      {{ $t('cart.minimum', { amount: $options.filters.price(cart.getMinimumPrice()) }) }}
    </div>
    <Totals :partner-id="partnerId" />
  </div>
</template>

<script>
import CartCommandeShop from '@/components/CartCheckout/CartCommandeShop.vue'
import Totals from '@/components/CartCheckout/Totals.vue'
export default {
  name: 'CartCommande',
  components: {
    CartCommandeShop,
    Totals
  },
  props: {
    partnerId: { type: String },
    minimumReached: { type: Boolean }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    shops() {
      return this.$store.getters['cart/shops']
    },
    cart() {
      return this.$store.getters['cart/cart']
    },
    minimumReachedLocal() {
      if (!this.shops.length) {
        return true
      }
      const salepoints = this.cart.getUnHoldSalepointsWithHubs()
      const notReached = salepoints.filter(sp => !sp.shop.minimumReached(null, this.cart))
      if (notReached.length) {
        return false
      }
      return this.cart.minimumReached()
    }
  },
  watch: {
    minimumReachedLocal(v) {
      this.$emit('update:minimum-reached', v)
    }
  },
  mounted() {
    this.loading = true
    return this.$store.dispatch('cart/get', { partnerId: this.partnerId })
      .then(() => {
        if (this.minimumReachedLocal) {
          this.$emit('update:minimum-reached', this.minimumReachedLocal)
        }
      })
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#cart-commande {
    padding-top: 30px;
    text-align: left;

    @include media-breakpoint-up(md) {
        padding-top: 60px;
        padding-left: 5%;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 30px;
        padding-left: 10%;
    }

    .minimum-panier-msg {
      display: flex;
      align-items: center;
      padding: 0 30px;

      img {
        width: 50px;
        padding-right: 10px;
      }
    }
}
</style>
