<template>
  <span ref="svg" class="ypq-svg" @click="ev => $emit('click', ev)"></span>
</template>

<script>
export default {
  name: 'YpqSvg',
  props: {
    src: { type: String },
    colour: { type: String },
    mode: { type: String, default: 'fill' }
  },
  watch: {
    src: 'load'
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      const res = await this.$http.get(this.src)
      const reg = new RegExp(`(${this.mode})="#[^".]+"`, 'gi')
      this.$refs.svg.innerHTML = res.data.replace(reg, `$1="${this.colour}"`)
    }
  }
}
</script>
