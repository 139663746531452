<template>
  <div
    class="article container-fluid"
    :class="{ active1: active }"
  >
    <div class="row">
      <div class="col-12 col-lg-3 produit pb-1">
        <span v-if="offer.article.brand">{{ offer.article.brand }} </span>
        {{ offer.article.getTitle($l) }}
        <span v-if="offer.article.packaging.packet === 'frozen'" class="frozen">[{{ $t('article.packaging.packets.frozen') }}]</span>
      </div>
      <div class="col-6 col-lg-4 qty">
        <div class="row article-details">
          <div class="minus-plus">
            <ProduitQuantityEditor
              v-if="offer"
              edition
              :partner-id="partnerId"
              :shop-id="shopId"
              :offer="offer"
              :article="offer.getArticle()"
            />
            <div class="remove col-1 p-0 d-flex justify-content-end align-items-start">
              <YpqSvg src="/imgs/cart-remove-icon.svg" :colour="$config.theme.color.red" mode="stroke" @click="remove" />
            </div>
          </div>
          <div class="produit-detail">
            {{ offer.article.getDescription($l) }}
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end justify-content-md-start justify-content-lg-end col-6 col-lg-2 sous-total">
        {{ offer.getPrice() | price }}
      </div>
    </div>
  </div>
</template>

<script>
import YpqSvg from '@/components/YpqSvg'
import ProduitQuantityEditor from '@/components/Produits/ProduitQuantityEditor'
import lodash from 'lodash'

export default {
  name: 'CartCommandeOffer',
  components: { YpqSvg, ProduitQuantityEditor },
  props: {
    offer: { type: Object },
    shopId: { type: String }
  },
  data: () => ({
    loading: false,
    active: false,
    portions: null,
    quantity: null,
    selectedQuantity: null,
    quantities: []
  }),
  computed: {
    partnerId() {
      return this.$route.params.partnerId
    }
  },
  watch: {
    portions: 'update',
    selectedQuantity: 'update',
    quantity: 'update'
  },
  mounted() {
    this.quantity = this.offer.quantity
    this.portions = this.offer.portions
    this.selectedQuantity = this.offer.selectedQuantity
    this.quantities = (this.offer.getArticle().packaging.quantities || '')
      .split('\n')
      .filter(q => !!q)
      .map(q => Number(q))
  },
  methods: {
    toggleDetail() {
      this.active = !this.active
    },

    remove() {
      this.loading = true
      return this.$store.dispatch('cart/removeOffer', { offerId: this.offer.id, shopId: this.shopId })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    increment(quantity) {
      this.loading = true
      let promise

      const q = Math.round(this.offer.getQuantity() + quantity)

      if (q <= 0) {
        promise = this.$store.dispatch('cart/removeOffer', { offerId: this.offer.id, shopId: this.shopId })
      } else {
        promise = this.$store.dispatch('cart/updateOffer', { offerId: this.offer.id, shopId: this.shopId, quantity: q })
      }

      return promise
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    update: lodash.debounce(function() {
      if (this.portions === this.offer.portions && this.selectedQuantity === this.offer.selectedQuantity && this.quantity === this.offer.quantity) {
        return
      }
      this.loading = true
      return this.$store
        .dispatch('cart/updateOffer', {
          offerId: this.offer.id,
          shopId: this.shopId,
          quantity: this.quantity,
          portions: this.portions,
          selectedQuantity: this.selectedQuantity
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }, 800)
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#cart-commande-item .cart-item {
    background-color: var(--yellow-color);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: white;
    font-weight: 700;
    text-align: left;
    margin: 15px 0;

    @include media-breakpoint-up(md) {
        min-height: 70px;
    }

    .categorie-icon img {
      max-height: 30px;
      @include media-breakpoint-up(lg) {
        max-height: 40px;
      }
    }
    .sous-total {
      padding-left: 15px;
    }
    .arrow img, .remove {
      max-width: 30px;
      cursor: pointer;
    }
}
#cart-commande-item.active .arrow img {
    transform: rotate(90deg);
}

#cart-commande-item .cart-item-details.active {
    display: block;
}

.article.active1 {
    .article-details {
      display: flex !important;
    }
}

#cart-commande-item .cart-item-details {
    display: none;
    text-align: left;
    padding: 20px;
    .article {
      padding-bottom: 25px;
    }
    .produit {
      font-weight: 700;
    }
    .qty img{
        max-width: 25px;
        margin-right: 30px;
        cursor: pointer;
    }
    .sous-total {
      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }
    .frozen {
      color: var(--blue-color);
    }
    .article-details {
      //display: none;
      .minus-plus {
        display: flex;
        .quantity {
          padding-right: 10px;
        }
        img {
          max-width: 20px;
          margin-right: 5px;

          &:hover {
            cursor: pointer;
          }
        }

        .remove {
          cursor: pointer;
        }

        .input-group input, .group-portion-quantity select {
          border-radius: 10px !important;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          border: none;
          -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, .35);
          box-shadow: inset 1px 1px 2px rgba(0, 0, 0, .35);
          min-height: 60px;
          min-width: 100px;
          max-width: 150px;
          padding: 1rem 0.75rem;
        }

        .input-group {
          .input-group-text {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            background-color: var(--gray-color);
            color: white;
          }

          .input-group-text.middle {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            background-color: transparent !important;
            border: none;
            color: var(--gray-color);
          }
        }

        .form-floating {
          margin-top: 20px;
        }

        .form-floating input {
          border-radius: 10px !important;
          border: none;
          -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, .35);
          box-shadow: inset 1px 1px 2px rgba(0, 0, 0, .35);
          min-height: 60px;
          padding: -1rem 0.75rem;
        }
      }
      .produit-detail {
        font-weight: 300;
      }

      .portions {
        max-width: 200px;
      }
    }
}
</style>
