<template>
  <div>
    <CartCheckoutHeader
      :section1="true"
      :title="$t('cart.title')"
    />

    <CartCommande :partner-id="partnerId" :minimum-reached.sync="minimumReached" />
    <div class="container-fluid">
      <div class="row d-flex buttons flex-md-row-reverse">
        <div class="col-md-2" />
        <div class="col-12 col-md-4 d-flex justify-content-lg-end continuer-btn">
          <router-link :to="{ name: 'delivery' }">
            <Button
              color="red"
              :text="$t('cart.continue')"
              :disabled="!minimumReached"
            />
          </router-link>
        </div>
        <div class="col-12 d-flex justify-content-start col-md-6 justify-content-md-end">
          <router-link :to="{ name: 'shops' }">
            <Button
              color="red"
              :text="$t('cart.buy')"
            />
          </router-link>
        </div>
      </div>
    </div>
    <FooterSpacer />
  </div>
</template>

<script>
import CartCheckoutHeader from '@/components/CartCheckout/CartCheckoutHeader.vue'
import CartCommande from '@/components/CartCheckout/CartCommande.vue'
import Button from '@/components/UI/Button.vue'
import FooterSpacer from '@/components/Layout/FooterSpacer'

export default {
  name: 'ViewCart',
  components: {
    CartCheckoutHeader,
    CartCommande,
    Button,
    FooterSpacer
  },
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    minimumReached: false
  }),
  computed: {
    cart() {
      return this.$store.getters['cart/cart']
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/youpaq.scss';

.continuer-btn {
  padding-right: 125px;
}

</style>
