<template>
  <div
    id="cart-commande-item"
    :class="{ active: cartActive }"
  >
    <div class="row cart-item">
      <div class="commercant col-12 col-md-6 col-lg-3 p-md-3">
        {{ shop.name }}
      </div>
      <div class="qty col-12 col-md-5 col-lg-2">
        {{ $t('cart.articles', { count: offers.length }) }}
      </div>
      <div class="col-10 offset-md-6 col-md-5 offset-lg-0 col-lg-4 d-flex flex-direction-row justify-content-lg-end">
        {{ $t('cart.shopTotal') }} <span class="sous-total">{{ shop.getTotalPriceStuff() | price }}</span>
      </div>
      <div class="col-2 col-md-1 col-lg-2 arrow">
        <img
          src="@/assets/cart-item-arrow.svg"
          @click="cartActive = !cartActive"
        >
      </div>
      <div v-if="!shop.minimumReached(null, cart)" class="col-12 minimum-price-msg">
        <img src="@/assets/attention-red.svg">
        {{ $t('cart.minimumShort', { amount: $options.filters.price(shop.getMinimumPrice(null, cart)) }) }}
      </div>
    </div>

    <div
      class="cart-item-details"
      :class="{ active: cartActive }"
    >
      <CartCommandeOffer
        v-for="offer in offers"
        :key="offer.id"
        :shop-id="shop.id"
        :offer="offer"
      />
    </div>
  </div>
</template>

<script>
import CartCommandeOffer from '@/components/CartCheckout/CartCommandeOffer'

export default {
  name: 'CartCommandeShop',
  components: { CartCommandeOffer },
  props: {
    shop: { type: Object },
    offers: { type: Array }
  },
  data() {
    return {
      loading: false,
      cartActive: false,
      articleActive: {}
    }
  },
  computed: {
    cart() {
      return this.$store.getters['cart/cart']
    }
  },
  methods: {
    toggleDetail(offer) {
      const active = this.articleActive[offer.id]
      if (active) {
        this.articleActive[offer.id] = false
      } else {
        this.$set(this.articleActive, offer.id, true)
      }
    },

    increment(offer, quantity) {
      this.loading = true
      let promise

      const q = Math.round(offer.getQuantity() + quantity)

      if (q <= 0) {
        promise = this.$store.dispatch('cart/removeOffer', { offerId: offer.id, shopId: this.shop.id })
      } else {
        promise = this.$store.dispatch('cart/updateOffer', { offerId: offer.id, shopId: this.shop.id, quantity: q })
      }

      return promise
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#cart-commande-item .cart-item {
    background-color: var(--yellow-color);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: white;
    font-weight: 700;
    text-align: left;
    margin: 15px 0;

    @include media-breakpoint-up(md) {
        min-height: 70px;
    }

    .categorie-icon img {
      max-height: 30px;
      @include media-breakpoint-up(lg) {
        max-height: 40px;
      }
    }
    .sous-total {
      padding-left: 15px;
    }
    .arrow img {
      max-width: 30px;
      cursor: pointer;
    }

    .minimum-price-msg {
      color: white;

      img {
        width: 40px;
      }
    }
}
#cart-commande-item.active .arrow img {
    transform: rotate(90deg);
}

#cart-commande-item .cart-item-details.active {
    display: block;
}

.article.active1 {
    .article-details {
      display: flex !important;
    }
    .qty img {
      transform: rotate(90deg);
    }
}

#cart-commande-item .cart-item-details {
    display: none;
    text-align: left;
    padding: 20px;
    .article {
      padding-bottom: 25px;
    }
    .produit {
      font-weight: 700;
    }
    .qty img{
        max-width: 25px;
        margin-right: 30px;
        cursor: pointer;
    }
    .sous-total {
      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }
    .article-details {
      display: none;
      .minus-plus {
        padding-left: 70px;
        img {
          max-width: 20px;
          margin-right: 10px;
        }
      }
      .produit-detail {
        font-weight: 300;
      }
    }
}
</style>
